<template>
	<div class="pageContainer targetPage flexCloumn">
		<el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1" style="flex-wrap:wrap;">

				<el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">新增</el-button>

			</div>
		</el-row>
		<div class="dividerBar"></div>

		 <el-table v-tableFit :data="tableData" default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
			height="100%" size="small" stripe border style="width:fit-content;width:100%;">
			<el-table-column type="index" label="序号" width="65"></el-table-column>
			<el-table-column prop="title" min-width="120" label="周期名称"></el-table-column>
			<el-table-column prop="start_date" min-width="100" label="开始时间"></el-table-column>
			<el-table-column prop="end_date" min-width="100px" label="结束时间"></el-table-column>
			<el-table-column fixed="right" label="操作" width="350px">
				<template slot-scope="scope">
					<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
						iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
						<el-tag class="pointer" slot="reference" size="mini" type="danger">删除</el-tag>
					</el-popconfirm>
					<el-tag class="pointer ml10" type="primary" size="mini" @click="handleModify(scope.row)">修改</el-tag>
					<el-tag class="pointer ml10" type="success" size="mini" @click="generateMenu(scope.row)">生成月份菜单</el-tag>
					<el-tag v-if="scope.row.status" class="pointer ml10" type="success" size="mini" @click="openCloseMenu(scope.row,0)">关闭菜单</el-tag>
					<el-tag v-if="!scope.row.status" class="pointer ml10" type="success" size="mini" @click="openCloseMenu(scope.row,1)">开启菜单</el-tag>
				</template>
			</el-table-column>
		</el-table>


		<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			custom-class="cus_dialog">
			<el-form :model="formData" ref="ruleForm" label-width="120px" :rules="rules"
				style="max-height: 450px;overflow-y: auto;min-height: 300px;">

				<el-form-item label="实习周期名称" prop="title">
					<el-input v-model.trim="formData.title" placeholder="请输入实习周期名称" style="width: 50%" />
				</el-form-item>
				<el-form-item label="实习周期范围" prop="date_range">
          <el-date-picker
            v-model="formData.date_range"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
				</el-form-item>
			</el-form>
      <div slot="footer">
        <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </div>
		</el-dialog>


		<el-dialog title="角色菜单" :visible.sync="dialogAuth" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px" custom-class="cus_dialog">
			<el-tree ref="authTree" :props="{ children: 'children', label: 'title' }" node-key="id" :data="authData"
				:default-expand-all="true" show-checkbox>

			</el-tree>

			<div slot="footer" style="text-align: right; padding: 10px; margin-top: 10px; border-top: 1px solid #ccc;">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveAuthMenu">保存</el-button>
			</div>
		</el-dialog>

		<el-dialog title="角色模块" :visible.sync="dialogModule" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px" custom-class="cus_dialog">
			<el-tree ref="moduleTree" :props="{ children: 'children', label: 'name' }" node-key="id" :data="modulesData"
				:default-expand-all="true" show-checkbox>

			</el-tree>

			<div slot="footer" style="text-align: right; padding: 10px; margin-top: 10px; border-top: 1px solid #ccc;">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveModules">保存</el-button>
			</div>
		</el-dialog>
		<ziduan v-if="zdDialog" :roleid="zdDialog"></ziduan>
	</div>
</template>
<script>
import api from '@/api/api';
import ziduan  from "./components/downZiduan";
export default {
	name: 'deptRole',
	components:{ziduan},
	data() {
		return {
			visible: false,
			isShowModal: false,
			dialogModule: false,
			dialogAuth: false,
			zdDialog:false,
			modalTitle: '添加',
			formData: {},
			firstCascaderProps: {
				label: 'dept_name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			//table
			tabMapOptions: [{
				label: '系统角色',
				key: 'CN'
			},],
			activeName: 'CN',
			tableData: [],
			rules: {
				title: [{
					required: true,
					message: '请输入周期名称',
					trigger: 'blur'
				}],
        date_range: [{
					required: true,
					message: '请选择日期范围',
					trigger: 'change'
				},],
			},
			isShowMenu: false,
			menuTitle: '',
			menuIds: '',
			roleId: null,

			treeData: [],
			treeProps: {
				children: 'children',
				label: 'dept_name'
			},
			authData: [],
			modulesData: [],
			activeItem: null,
			modulesItem: null
		}
	},
	watch: {
		'formData.dept_id'() {
			if (this.$refs.firstCascader) {
				this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
			}
		}
	},
	mounted() {
		this.getList();
	},
	methods: {

		getList() {
			this.$http.post("/api/sys_sxzq_list", {
				pagesize: 100
			}).then(res => {
				this.tableData = res.data.data
			})
		},
		formAdd() {
			this.isShowModal = true;
			this.formData = {
				sort: 1,
			}
			this.modalTitle = '添加'
		},
		handleModify(row) {
			this.isShowModal = true;
			this.modalTitle = '修改';
      row.date_range = [row.start_date,row.end_date]
			this.formData = {...row}
		},
		handleDel(row) {
			console.log(row)
			this.$http.post('/api/sys_sxzq_delete', {
				id: row.id
			}).then(res => {
				this.$message.success('删除成功')
				this.getList();
			})
		},
		handleCascaderChange() {

		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
          let formData = {...this.formData}
          formData.start_date = formData.date_range[0]
          formData.end_date = formData.date_range[1]
          if (!formData.id) {
            formData.year = new Date().getFullYear()
          }
					this.$http.post("/api/sys_sxzq_edit", formData).then(res => {
						this.$message.success('保存成功')
						this.getList();
						this.isShowModal = false;
						return
						if (res.code == 200) {
							this.$message.success(res.msg)
							this.getList();
							this.isShowModal = false;
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			})
		},
		generateMenu(row) {
      this.$confirm('确认生成当前实习周期的所有月份菜单？',{
        type: 'warning'
      }).then(res => {
        this.$http.post('/api/generateMenu', {id:row.id}).then(res => {
          this.$message.success('操作成功')
        })
      })
    },
    openCloseMenu(row,status) {
      this.$confirm(`确认${status ? '开启' : '关闭'}当前实习周期的所有月份菜单？`,{
        type: "warning"
      }).then(res => {
        this.$http.post('/api/openOrCloseMenu', {id:row.id,status: status}).then(res => {
          this.$message.success('操作成功')
          this.getList()
        })
      })
    }
	}
}
</script>

<style scoped lang="less" type="text/less">
.roleBox {
	height: 100%;

	.el-row,
	.el-row .el-col {
		height: 100%;
	}

	.el-tabs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.el-tabs__content {
		flex: 1;

		.el-tab-pane {
			height: 100%;
		}
	}

	.treeContainer {
		height: 100%;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
		border: 1px solid rgba(220, 223, 230, 0.6);

		.treeTitle {
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #409eff;
			padding-left: 6px;
		}
	}
}
</style>
